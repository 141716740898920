var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { Redirect, withRouter } from 'react-router';
import { Formik, Form } from 'formik';
import axios from 'axios';
import _ from 'lodash';
import { withVin } from '../application/vin-context';
import CampaignForm, { CampaignFormSchema } from './campaign-form';
import ContactForm, { ContactFormSchema } from './contact-form';
import DisclaimerForm from './disclaimer-form';
import { isNoRemedyStatus, isOpenStatus, statusConversion } from '../types/campaign';
import GeneralMessage from '../ui/general-message';
import ProgressBar from './progress-bar';
import ClaimInfo from '../application/claim-info';
import Loader from '../ui/loader';
import { Link } from 'react-router-dom';
import Modal from "../ui/modal";
import LegacyRecalls from "./legacy-recalls";
import { isObjectError } from '../utils/is-object-error';
import { CAMPAIGN_OPEN_RECALL_MESSAGE, CLAIM_SUCCESS_MESSAGE, CLAIM_ERROR_MESSAGE, NO_CAMPAIGNS_MESSAGE, OPEN_RECALLS_MESSAGE, PROCESSING_ERROR_MESSAGE, NO_REMEDY_RECALLS_MESSAGE } from "../constants";
import { parse as parseQuery } from 'query-string';
import { Territory } from "../utils/territory";
import { getCampaignById } from '../utils/get-campaign';
var styles = require('./claim-wizard.scss');
//Percentages are hardcoded because the steps are not uniform length
export var ProgressStatus;
(function (ProgressStatus) {
    ProgressStatus["CAMPAIGN"] = "25%";
    ProgressStatus["CONTACT"] = "50%";
    ProgressStatus["DISCLAIMER"] = "75%";
    ProgressStatus["COMPLETE"] = "100%";
})(ProgressStatus || (ProgressStatus = {}));
var FormSchema = mergeSchemas(CampaignFormSchema, ContactFormSchema);
function mergeSchemas() {
    var schemas = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        schemas[_i] = arguments[_i];
    }
    var _a = __read(schemas), first = _a[0], rest = _a.slice(1);
    var merged = rest.reduce(function (mergedSchemas, schema) { return mergedSchemas.concat(schema); }, first);
    return merged;
}
var ClaimWizard = /** @class */ (function (_super) {
    __extends(ClaimWizard, _super);
    function ClaimWizard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            campaigns: [],
            progress: ProgressStatus.CAMPAIGN,
            claim: null,
            isLoading: true,
            isError: false,
            isInvalid: false,
            showPrompt: false,
            hasOpen: false,
            hasNoRemedy: false,
            territory: undefined
        };
        _this.initialFormValues = {
            campaignId: '',
            mileage: '',
            firstName: '',
            lastName: '',
            addressOne: '',
            addressTwo: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            phoneNumber: '',
            emailAddress: '',
            amount: '',
            soldVehicle: "NO",
            additionalInformation: '',
            files: []
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var queryString, campaigns, _a, _b, err_1, hasErrorStatus, stateChangeName_1, selectedId, campaignId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, 5, 6]);
                        if (!this.props.submittable.length) return [3 /*break*/, 2];
                        queryString = _.map(this.props.submittable, 'servicedeskId').map(function (id) {
                            return "ids[]=" + id;
                        }).join('&');
                        return [4 /*yield*/, axios.get("/api/campaigns?" + queryString)];
                    case 1:
                        campaigns = (_c.sent()).data.campaigns;
                        this.appendStatus(campaigns);
                        this.setState({ campaigns: campaigns });
                        _c.label = 2;
                    case 2:
                        _a = this.setState;
                        _b = {};
                        return [4 /*yield*/, Territory.lookupTerritory(this.props.vin)];
                    case 3:
                        _a.apply(this, [(_b.territory = _c.sent(), _b)]);
                        this.checkStatuses();
                        return [3 /*break*/, 6];
                    case 4:
                        err_1 = _c.sent();
                        hasErrorStatus = err_1.hasOwnProperty('response') &&
                            err_1.response.hasOwnProperty('status');
                        stateChangeName_1 = hasErrorStatus && err_1.response.status == 404 ? 'isInvalid' : 'isError';
                        this.setState(function (prevState) {
                            var _a;
                            return (__assign({}, prevState, (_a = {}, _a[stateChangeName_1] = true, _a)));
                        });
                        console.error('Error occurred in retrieving campaigns', err_1);
                        return [3 /*break*/, 6];
                    case 5:
                        this.setState({ isLoading: false });
                        selectedId = parseQuery(this.props.location.search).selectedId;
                        if (this.isCampaign(selectedId)) {
                            campaignId = selectedId;
                            this.setCampaignIdToFormValues(campaignId);
                            this.moveToContact();
                        }
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.isCampaign = function (servicedeskId) {
            return !!_.find(_this.state.campaigns, ['id', servicedeskId]);
        };
        _this.setCampaignIdToFormValues = function (campaignId) {
            _this.initialFormValues.campaignId = campaignId;
        };
        _this.messages = function () {
            return (React.createElement("section", null,
                _this.state.territory !== undefined && !_this.state.isLoading && (React.createElement(GeneralMessage, { type: "warning", title: _this.state.territory.title, description: _this.state.territory.message })),
                _this.state.isInvalid && !_this.state.isLoading && (React.createElement(GeneralMessage, { type: "warning", title: "Invalid Request", description: PROCESSING_ERROR_MESSAGE },
                    React.createElement(Link, { to: "/" }, "Try another Vin"))),
                !_this.state.campaigns.length && !_this.state.isLoading && (React.createElement(GeneralMessage, { type: "warning", title: "No Customer Reimbursement Campaigns found for your VIN", description: NO_CAMPAIGNS_MESSAGE, dataTest: "no_campaign_notice" },
                    React.createElement(Link, { to: "/" }, "Try another Vin"))),
                _this.state.hasOpen && _this.state.progress === ProgressStatus.CAMPAIGN && (React.createElement(GeneralMessage, { type: "warning", title: "Incomplete/Remedy Available", description: OPEN_RECALLS_MESSAGE, dataTest: "open_notice" })),
                _this.state.hasNoRemedy && _this.state.progress === ProgressStatus.CAMPAIGN && (React.createElement(GeneralMessage, { type: "warning", title: "Incomplete/No Remedy Found", description: NO_REMEDY_RECALLS_MESSAGE, dataTest: "no_remedy_notice" })),
                _this.state.progress === ProgressStatus.COMPLETE && (React.createElement(GeneralMessage, { type: "success", title: "Claim Submitted", description: CLAIM_SUCCESS_MESSAGE, dataTest: "claim_submitted_notice" }))));
        };
        _this.statusBar = function () { return (React.createElement(ProgressBar, { progress: _this.state.progress })); };
        _this.campaignHeader = function () { return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("h3", { className: styles.lookUpHeaderTitle }, "Mazda Recall/SSP Number and Recall Description"),
                React.createElement("h3", { className: styles.lookUpHeaderStatus }, "Status")))); };
        _this.form = function () {
            return (React.createElement(React.Fragment, null,
                React.createElement(Modal, { showModal: _this.state.showPrompt, onConfirm: _this.promptAcknowledged, dataTestPrefix: "open_acknowledgement" },
                    React.createElement("h2", null, "Attention"),
                    React.createElement("div", null, CAMPAIGN_OPEN_RECALL_MESSAGE)),
                React.createElement(Formik, { initialValues: _this.initialFormValues, validationSchema: FormSchema, onSubmit: _this.submit }, function (_a) {
                    var status = _a.status, submitForm = _a.submitForm, isSubmitting = _a.isSubmitting, values = _a.values;
                    return (React.createElement("section", null,
                        isSubmitting && React.createElement(Loader, null),
                        status && status.error && (React.createElement(GeneralMessage, { type: "error", title: "System Error", description: status.error })),
                        React.createElement(Form, null,
                            _this.state.progress === ProgressStatus.CAMPAIGN && (React.createElement(CampaignForm, { campaigns: _this.state.campaigns, updateProgress: function () { return _this.moveToContact(values); } })),
                            _this.state.progress === ProgressStatus.CONTACT && (React.createElement(ContactForm, { campaigns: _this.state.campaigns, updateProgress: function () { return _this.moveToDisclaimer(); } })),
                            _this.state.progress === ProgressStatus.DISCLAIMER && (React.createElement(DisclaimerForm, { vin: _this.props.vin, updateProgress: function () { return submitForm(); } })),
                            _this.state.progress === ProgressStatus.COMPLETE && React.createElement(ClaimInfo, { claim: _this.state.claim }))));
                })));
        };
        _this.legacy = function () { return (React.createElement(LegacyRecalls, { recalls: _this.props.legacy })); };
        _this.render = function () {
            // scroll to top of screen
            window.scrollTo(0, 0);
            if (!_this.props.vin) {
                return React.createElement(Redirect, { to: "/" });
            }
            if (_this.state.isError) {
                return React.createElement(Redirect, { to: "/error" });
            }
            return (React.createElement("article", { className: styles.wizardContainer }, [
                _this.messages(),
                _this.state.isLoading ? React.createElement(Loader, null) : undefined,
                !_this.state.isLoading ? _this.statusBar() : undefined,
                _this.shouldRenderHeader() ? _this.campaignHeader() : undefined,
                _this.shouldRenderForm() ? _this.form() : undefined,
                _this.shouldRenderLegacy() ? _this.legacy() : undefined
            ]));
        };
        _this.shouldRenderHeader = function () {
            return _this.shouldRenderLegacy() || (_this.shouldRenderForm() && _this.state.progress === ProgressStatus.CAMPAIGN);
        };
        _this.shouldRenderForm = function () {
            return _this.state.campaigns.length && !_this.state.isLoading && !_this.state.isInvalid;
        };
        _this.shouldRenderLegacy = function () {
            return _this.props.legacy.length && _this.state.progress === ProgressStatus.CAMPAIGN;
        };
        _this.promptAcknowledged = function () {
            _this.setState({ showPrompt: false });
            _this.moveToContact();
        };
        _this.moveToContact = function (values) {
            if (values !== undefined) {
                var campaignId = values.campaignId;
                var status_1 = getCampaignById(campaignId, _this.state.campaigns).status;
                if (isOpenStatus(status_1)) {
                    _this.setState({ showPrompt: true });
                }
                else {
                    _this.setState({ progress: ProgressStatus.CONTACT });
                }
            }
            else {
                _this.setState({ progress: ProgressStatus.CONTACT });
            }
        };
        _this.moveToDisclaimer = function () {
            _this.setState({ progress: ProgressStatus.DISCLAIMER });
        };
        _this.submit = function (values, formikActions) { return __awaiter(_this, void 0, void 0, function () {
            var campaignId, files, data, formData_1, campaign, response, claim, err_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        campaignId = values.campaignId, files = values.files, data = __rest(values, ["campaignId", "files"]);
                        formData_1 = new FormData();
                        campaign = getCampaignById(campaignId, this.state.campaigns);
                        formData_1.append('vin', this.props.vin);
                        formData_1.append('campaignId', campaign.id);
                        formData_1.append('campaignProjectId', campaign.projectId);
                        _.forEach(data, function (val, key) {
                            if (_this.initialFormValues.hasOwnProperty(key)) {
                                formData_1.append(key, val);
                            }
                        });
                        _.forEach(files, function (file) { return formData_1.append('files', file, file.name); });
                        return [4 /*yield*/, axios.post('/api/claims', formData_1)];
                    case 1:
                        response = _a.sent();
                        claim = __assign({}, values, response.data.claim, { campaign: campaign });
                        formikActions.setStatus({
                            error: null
                        });
                        this.setState({ progress: ProgressStatus.COMPLETE, claim: claim });
                        return [3 /*break*/, 4];
                    case 2:
                        err_2 = _a.sent();
                        if (isObjectError(err_2, 'InvalidFileError')) {
                            formikActions.setStatus({
                                error: err_2.response.data.error.message
                            });
                        }
                        else {
                            formikActions.setStatus({
                                error: CLAIM_ERROR_MESSAGE
                            });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        formikActions.setSubmitting(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.checkStatuses = function () {
            for (var index in _this.props.submittable) {
                var statusCode = _this.props.submittable[index].mfr_recall_status;
                if (isOpenStatus(statusCode)) {
                    _this.setState({ hasOpen: true });
                }
                if (isNoRemedyStatus(statusCode)) {
                    _this.setState({ hasNoRemedy: true });
                }
            }
            for (var index in _this.props.legacy) {
                var statusCode = _this.props.legacy[index].mfr_recall_status;
                if (isOpenStatus(statusCode)) {
                    _this.setState({ hasOpen: true });
                }
                if (isNoRemedyStatus(statusCode)) {
                    _this.setState({ hasNoRemedy: true });
                }
            }
        };
        _this.appendStatus = function (campaigns) {
            for (var index in campaigns) {
                var statusCode = _.find(_this.props.submittable, ['servicedeskId', Number(campaigns[index].id)]).mfr_recall_status;
                try {
                    campaigns[index].status = statusConversion(statusCode);
                }
                catch (error) {
                    console.error('statusCode', statusCode);
                }
            }
        };
        return _this;
    }
    return ClaimWizard;
}(React.Component));
export default withRouter(withVin(ClaimWizard));
